import { asyncReducer } from "async-lifecycle";
import empty from "empty";
import { combineReducers } from "redux";

import { arrayFilter, drop } from "../utils";

const minisaarClear = (
  state = empty.object,
  { type, extra: { changed = false } = empty.object }
) => (changed && type === "MINISAAR_INIT" ? empty.object : state);

/**
 * Page-reducer; this reducers creates data needed for a page
 * @return Redux reducer
 */
const pages = asyncReducer("PAGE", {
  hasId: true,
  DELETE: (
    state = empty.object,
    { payload: { id = 0 } = empty.object } = empty.object
  ) => drop(state, id),
});

/**
 * Relations-reducer; this reducers creates data needed for a page relations
 * @return Redux reducer
 */
const relations = asyncReducer("RELATIONS", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  CMS_LEGOBLOK_CREATE_SUCCESS: "INVALIDATEALL",
});

const releases = asyncReducer("RELEASES", {
  PATCH: (state, { payload }) => ({ ...state, ...payload }),
});

const chainRelations = asyncReducer("CHAINRELATIONS", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  CMS_LEGOBLOK_CREATE_SUCCESS: "INVALIDATEALL",
});

/**
 * Landscape-reducer; this reducers creates data needed for the application landscape
 * @return Redux reducer
 */
const landscape = asyncReducer("LANDSCAPE", {
  hasId: true,
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  SAVE_PAGE_SUCCESS: "INVALIDATEALL",
});

/**
 * Entitylandscape-reducer; this reducers creates data needed for the entity landscape
 * @return Redux reducer
 */
const entitylandscape = asyncReducer("ENTITYLANDSCAPE", {
  hasId: true,
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  SAVE_PAGE_SUCCESS: "INVALIDATEALL",
});

/**
 * ExpandedLandscape-reducer; this reducers creates data needed for the application landscape
 * @return Redux reducer
 */
const expandedLandscape = asyncReducer("EXPANDED_LANDSCAPE", {
  hasId: true,
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  SAVE_PAGE_SUCCESS: "INVALIDATEALL",
});

/**
 * Raamwerk reducer; this reducers creates data needed for the raamwerk treeview
 * @return Redux reducer
 */
const raamwerk = asyncReducer("RAAMWERK", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
  PAGE_INVALIDATE: "INVALIDATEALL",
});

/**
 * RaamwerkPagetypes reducer; this reducers creates data needed for the raamwerk
 * link dialog in the treeview
 * @return Redux reducer
 */
const raamwerkPagetypes = asyncReducer("RAAMWERKPAGETYPES", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * Tree-reducer; this reducers creates data needed for the tree
 * @return Redux reducer
 */
const tree = asyncReducer("TREE", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * Siblings-reducer; this reducers creates data needed for the tree
 * @return Redux reducer
 */
const siblings = asyncReducer("SIBLINGS", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * selection-reducer; this reducers creates data needed for a page selection
 * @return Redux reducer
 */
const selection = asyncReducer("SELECTION", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
});

/**
 * SelectionLists-reducer; this reducers creates data needed for selection lists
 * @return Redux reducer
 */
const selectionLists = asyncReducer("SELECTIONLISTS", {
  selector: ({ data }) => ({ data: data.lists }),
  MINISAAR_INIT: "INVALIDATEALL",
});

/**
 * PagetypeList-reducer; this reducers creates data needed for pagetype list
 * @return Redux reducer
 */
const pagetypeList = asyncReducer("PAGETYPELIST", {
  selector: ({ data }) => ({ data: data.pagetypes }),
  MINISAAR_INIT: "INVALIDATEALL",
});

/**
 * RelationTypes-reducer; this reducers creates data needed for a page
 * @return Redux reducer
 */
const relationTypes = asyncReducer("RELATIONTYPES", {
  MINISAAR_INIT: "INVALIDATEALL",
});

/**
 * Navigation reducer; this reducers creates data needed for navigation
 * @return Redux reducer
 */
const navigation = asyncReducer("NAVIGATION", {
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * Mini SAAR's reducer
 * @return Redux reducer
 */
const miniSaars = asyncReducer("MINISAARS", {
  selector: ({ data }) => ({
    data: data.miniSaars,
    sharedStructureIds: data.sharedStructureIds,
  }),
});

/**
 * Reducer for the autocomplete searchbox
 * @return Redux reducer
 */
const autoComplete = asyncReducer("AUTOCOMPLETE_SEARCH", {
  CLEAR: () => empty.object,
  MINISAAR_INIT: minisaarClear,
});

/**
 * Reducer for the global search
 * @return Redux reducer
 */
const searchResult = asyncReducer("GLOBAL_SEARCH", {
  CLEAR: () => empty.object,
  MINISAAR_INIT: minisaarClear,
});

/**
 * Recent-reducer; this reducers creates a list with recently visited items
 * @return Redux reducer
 */
const recent = asyncReducer("RECENT_ITEMS", {
  UPDATE: (state = empty.object, { payload }) => {
    if (
      state.items &&
      state.items.length > 0 &&
      state.items[0].itemId === payload.itemId
    ) {
      return state;
    }

    const items = state.items
      ? state.items.filter(({ itemId }) => itemId !== payload.itemId)
      : empty.array;
    return {
      ...state,
      items: [payload, ...items].slice(0, 11),
    };
  },
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * Recent-reducer; this reducers creates a list with recently generated overviews
 * @return Redux reducer
 */
const overzicht = asyncReducer("OVERZICHT", {
  MINISAAR_INIT: minisaarClear,
});

const recentlyCreated = asyncReducer("RECENTLYCREATED_ITEMS", {
  MINISAAR_INIT: minisaarClear,
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

/**
 * ItemNotes-reducer; this reducers creates data needed for a page
 * @return Redux reducer
 */
const notes = asyncReducer("ITEM_NOTES", {
  hasId: true,
  DELETE_ITEM_NOTE: (state = empty.object, { payload }) => {
    const container = state[payload.id];
    if (!container) {
      return state;
    }

    const notes = arrayFilter((n) => n.id !== payload.noteId)(container.notes);
    return {
      ...state,
      [payload.id]: { ...container, notes },
    };
  },
});

const fieldDefinitions = asyncReducer("DEFINITION_FIELD", {
  selector: ({ data: { definitions = empty.array } = empty.object }) => ({
    definitions: definitions.map(
      ({ definition = empty.object, pagetypes = empty.array, cluster }) => ({
        ...definition,
        pagetypes,
        cluster,
      })
    ),
  }),
  MINISAAR_INIT: "INVALIDATEALL",
});

const imageLightbox = (
  state = empty.object,
  { type, payload = empty.object }
) => (type === "IMAGE_LIGHTBOX_TOGGLE" ? payload : state);

const settings = asyncReducer("SETTINGS");

const foundItems = asyncReducer("CMS_FIND_ITEM", {
  selector: ({ data = empty.array }) => ({ list: data }),
});

const mediaWidgets = asyncReducer("MEDIAWIDGET", { hasId: true });

const clusters = asyncReducer("CLUSTERDEFINITION", {
  PICK: (state, _) => state,
  hasId: true,
});

const addLegoblok = asyncReducer("CMS_LEGOBLOK_STRUCTURES", {
  MINISAAR_INIT: minisaarClear,
});

const legoblokReferences = asyncReducer("CMS_LEGOBLOK_REFERENCES", {
  hasId: true,
  MINISAAR_INIT: minisaarClear,
});

const verwijzingSearch = asyncReducer("SEARCH_ITEM_LINKDIALOG", {
  CLEAR: () => empty.object,
  MINISAAR_INIT: minisaarClear,
});

const koppelingFields = asyncReducer("KOPPELING_FIELDS", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

const entiteiten = asyncReducer("ENTITEITEN", { MINISAAR_INIT: minisaarClear });

const zibs = asyncReducer("ZIBS", { MINISAAR_INIT: minisaarClear });
const zibExpansion = asyncReducer("ZIBEXPANSION", {
  MINISAAR_INIT: minisaarClear,
});

const verantwoordingen = asyncReducer("VERANTWOORDINGEN", {
  MINISAAR_INIT: minisaarClear,
});

const verwerkingen = asyncReducer("VERWERKINGEN", {
  MINISAAR_INIT: minisaarClear,
});

const fieldDefinitionsByPagetype = asyncReducer(
  "FIELDDEFINITIONS_BY_PAGETYPE",
  { hasId: true, MINISAAR_INIT: "INVALIDATEALL" }
);

const presentation = asyncReducer("PRESENTATION", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
});

const versions = asyncReducer("VERSIONS", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
  CMS_DELETE_ITEM_SUCCESS: "INVALIDATEALL",
});

const versionDiffs = asyncReducer("VERSIONDIFF", {
  hasId: true,
  MINISAAR_INIT: "INVALIDATEALL",
});

const exportWord = asyncReducer("EXPORTWORD");

export default combineReducers({
  addLegoblok,
  autoComplete,
  chainRelations,
  clusters,
  entiteiten,
  entitylandscape,
  expandedLandscape,
  exportWord,
  fieldDefinitions,
  fieldDefinitionsByPagetype,
  foundItems,
  imageLightbox,
  koppelingFields,
  landscape,
  legoblokReferences,
  mediaWidgets,
  miniSaars,
  navigation,
  notes,
  overzicht,
  pages,
  pagetypeList,
  presentation,
  raamwerk,
  raamwerkPagetypes,
  recent,
  recentlyCreated,
  relations,
  relationTypes,
  releases,
  searchResult,
  selection,
  selectionLists,
  settings,
  siblings,
  tree,
  verantwoordingen,
  versionDiffs,
  versions,
  verwerkingen,
  verwijzingSearch,
  zibs,
  zibExpansion,
});
