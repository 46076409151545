import empty from "empty";
import {
  chunk,
  compact,
  filter,
  flatten,
  flow,
  intersection,
  intersectionWith,
  keyBy,
  map,
  reject,
  some,
  sortBy,
  uniq,
  uniqBy,
  values,
} from "lodash/fp";
import {
  compose,
  mapProps,
  setDisplayName,
  withHandlers,
  withState,
} from "recompose";
import { createSelector } from "reselect";

import { flatItems, flown, push, toSet } from "../lodash";
import { mapValues } from "../utils";
import connect from "./connect";
import { filterValues, groupFilters } from "./enhanceUtil";

const idSelector = (_, { params: { id } = empty.object }) => parseInt(id, 10);

const landscapesSelector = ({
  data: {
    landscape = empty.object,
    expandedLandscape = empty.object,
  } = empty.object,
}) => ({ landscape, expandedLandscape });

const landscapeSelectionSelector = createSelector(
  [idSelector, landscapesSelector],
  (id, { landscape, expandedLandscape }) =>
    landscape[id] || expandedLandscape[id] || empty.object
);

const landscapeSelectionsSelector = createSelector(
  [landscapeSelectionSelector],
  ({ selectionPerItem = empty.object }) => selectionPerItem
);

const landscapeBasesetsSelector = createSelector(
  [landscapeSelectionSelector],
  ({ basesetPerItem = empty.object }) => basesetPerItem
);

const landscapeBasesetListSelector = createSelector(
  [landscapeBasesetsSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const landscapeBewerkingenSelector = createSelector(
  [landscapeSelectionSelector],
  ({ bewerkingPerItem = empty.object }) => bewerkingPerItem
);

const landscapeBewerkingListSelector = createSelector(
  [landscapeBewerkingenSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const landscapeEntiteitenSelector = createSelector(
  [landscapeSelectionSelector],
  ({ entiteitPerItem = empty.object }) => entiteitPerItem
);

const landscapeEntiteitListSelector = createSelector(
  [landscapeEntiteitenSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const landscapeApplicatiesSelector = createSelector(
  [landscapeSelectionSelector],
  ({ applicatiePerItem = empty.object }) => applicatiePerItem
);

const landscapeApplicatieListSelector = createSelector(
  [landscapeApplicatiesSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const landscapeKerngegevensSelector = createSelector(
  [landscapeSelectionSelector],
  ({ kerngegevenPerItem = empty.object }) => kerngegevenPerItem
);

const landscapeKerngegevenListSelector = createSelector(
  [landscapeKerngegevensSelector],
  (kerngegevenPerItem) =>
    flow(
      map((k) => kerngegevenPerItem[k]),
      flatten,
      compact,
      uniqBy("pageClusterId"),
      sortBy(["pageClusterLabel"]),
      map(({ label, pageClusterId, pageClusterLabel, itemId, type }) => ({
        id: pageClusterId,
        value: `${pageClusterLabel} (${label})`,
        itemId,
        type,
      }))
    )(Object.keys(kerngegevenPerItem))
);

const landscapeKernveldenSelector = createSelector(
  [landscapeSelectionSelector],
  ({ kernveldPerItem = empty.object }) => kernveldPerItem
);

const landscapeKernveldListSelector = createSelector(
  [landscapeKernveldenSelector],
  (kernveldPerItem) =>
    flow(
      map((k) => kernveldPerItem[k]),
      flatten,
      compact,
      uniqBy("pageClusterId"),
      sortBy(["pageClusterLabel"]),
      map(({ label, pageClusterId, pageClusterLabel, itemId, type }) => ({
        id: pageClusterId,
        value: `${pageClusterLabel} (${label})`,
        itemId,
        type,
      }))
    )(Object.keys(kernveldPerItem))
);

const landscapeZibsSelector = createSelector(
  [landscapeSelectionSelector],
  ({ zibPerItem = empty.object }) => zibPerItem
);

const landscapeZibListSelector = createSelector(
  [landscapeZibsSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label, type }) => ({ id: itemId, value: label, type }))
  )
);

const landscapeApplicatieMozKoppelingSelector = createSelector(
  [landscapeSelectionSelector],
  ({ applicatieMozKoppelingPerItem = empty.object }) =>
    applicatieMozKoppelingPerItem
);

const landscapeApplicatieMozKoppelingListSelector = createSelector(
  [landscapeApplicatieMozKoppelingSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label }) => ({ id: itemId, value: label }))
  )
);

const landscapeKoppelingMozGegevensSelector = createSelector(
  [landscapeSelectionSelector],
  ({ koppelingMozGegevensPerItem = empty.object }) =>
    koppelingMozGegevensPerItem
);

const landscapeKoppelingMozGegevensListSelector = createSelector(
  [landscapeKoppelingMozGegevensSelector],
  flow(
    values,
    flatten,
    compact,
    uniqBy("itemId"),
    sortBy(["label"]),
    map(({ itemId, label }) => ({ id: itemId, value: label }))
  )
);

const selectionListsSelector = ({
  data: {
    selectionLists: { data = empty.array } = empty.object,
  } = empty.object,
}) => data;

const landscapePagetypes = createSelector(
  [landscapeSelectionSelector],
  ({ rectangles = empty.object, lines = empty.object }) => {
    const pagetypeByItemId = {};
    Object.values(rectangles).forEach(
      ({ item: { itemId, type } = empty.object }) => {
        if (itemId && type) pagetypeByItemId[itemId] = type;
      }
    );
    Object.values(lines).forEach(({ links }) =>
      links.forEach(({ siteLink: { itemId, type } = empty.object }) => {
        if (itemId && type) pagetypeByItemId[itemId] = type;
      })
    );
    return pagetypeByItemId;
  }
);

const listIdsByPagetypeSelector = createSelector(
  [
    ({
      data: {
        fieldDefinitions: { definitions = empty.array },
      },
    }) => definitions,
  ],
  (definitions) => {
    const listIdsByPagetype = {};
    definitions.forEach(({ selectionListId, pagetypes }) => {
      if (!selectionListId) {
        return;
      }

      pagetypes.forEach((pagetype) => {
        if (listIdsByPagetype[pagetype]) {
          listIdsByPagetype[pagetype].push(selectionListId);
        } else {
          listIdsByPagetype[pagetype] = [selectionListId];
        }
      });
    });
    return listIdsByPagetype;
  }
);

const mapState = createSelector(
  [
    landscapeSelectionsSelector,
    landscapeApplicatiesSelector,
    landscapeApplicatieListSelector,
    landscapeBasesetsSelector,
    landscapeBasesetListSelector,
    landscapeBewerkingenSelector,
    landscapeBewerkingListSelector,
    landscapeEntiteitenSelector,
    landscapeEntiteitListSelector,
    landscapeKerngegevensSelector,
    landscapeKerngegevenListSelector,
    landscapeKernveldenSelector,
    landscapeKernveldListSelector,
    landscapeApplicatieMozKoppelingSelector,
    landscapeApplicatieMozKoppelingListSelector,
    landscapeKoppelingMozGegevensSelector,
    landscapeKoppelingMozGegevensListSelector,
    selectionListsSelector,
    landscapePagetypes,
    listIdsByPagetypeSelector,
    landscapeZibsSelector,
    landscapeZibListSelector,
  ],
  (
    selections,
    applicaties,
    applicatieList,
    basesets,
    basesetList,
    bewerkingen,
    bewerkingList,
    entiteiten,
    entiteitList,
    kerngegevens,
    kerngegevenList,
    kernvelden,
    kernveldList,
    applicatieMozKoppeling,
    applicatieMozKoppelingList,
    koppelingMozGegevens,
    koppelingMozGegevensList,
    selectionLists,
    pagetypes,
    listIdsByPagetype,
    zibs,
    zibList
  ) => {
    const facets = flown(selections, values, flatten, compact, uniq);
    const missingLists = flown(
      selections,
      mapValues((selItmIdts) =>
        flown(
          selectionLists,
          reject(
            flow(
              flatItems,
              intersectionWith(
                (selItmIdt, { id }) => selItmIdt === id,
                selItmIdts
              ),
              some(Boolean)
            )
          ),
          map("id")
        )
      )
    );
    const cleanMissingLists = mapValues((missing, key) =>
      intersection(missing)(listIdsByPagetype[pagetypes[Number(key)]])
    )(missingLists);
    const allMissing = flown(Object.values(cleanMissingLists), flatten, uniq);
    const lists = flown(
      selectionLists,
      filter(
        flow(
          flatItems,
          intersectionWith((facet, { id }) => facet === id, facets),
          some(Boolean)
        )
      ),
      map((list) => {
        const hasEmpty = allMissing.includes(list.id);
        return {
          ...list,
          items: flown(
            facets,
            intersectionWith(({ id }, facet) => id === facet, flatItems(list)),
            push(
              hasEmpty
                ? [{ id: 0, listId: list.id, value: "Niet gevuld" }]
                : empty.array
            )
          ),
        };
      })
    );
    return {
      defaultFilter: empty.array,
      landscapeFilters: {
        selections,
        missingLists: cleanMissingLists,
        applicaties,
        applicatieList,
        basesets,
        basesetList,
        bewerkingen,
        bewerkingList,
        entiteiten,
        entiteitList,
        kerngegevens,
        kerngegevenList,
        kernvelden,
        kernveldList,
        applicatieMozKoppeling,
        applicatieMozKoppelingList,
        koppelingMozGegevens,
        koppelingMozGegevensList,
        facets,
        itemIds: Object.keys(pagetypes),
        lists,
        zibs,
        zibList,
      },
    };
  }
);

const enhanceLandscape = compose(
  setDisplayName("enhanceLandscape"),
  connect(mapState),
  withState("filter", "updateFilter", ({ defaultFilter }) => defaultFilter),
  withHandlers({
    addFilter:
      ({ filter, updateFilter }) =>
      (...args) => {
        const newFilter = [...filter];
        for (const [id, type] of chunk(2)(args)) {
          newFilter.push(`${type}:${id}`);
        }
        updateFilter(newFilter);
      },
    removeFilter:
      ({ filter, updateFilter }) =>
      (id, type) =>
        updateFilter(filter.filter((i) => i !== `${type}:${id}`)),
    removeAllFilters:
      ({ defaultFilter, updateFilter }) =>
      () =>
        updateFilter(defaultFilter),
  }),
  mapProps(
    ({
      landscapeFilters,
      landscapeFilters: {
        selections = empty.object,
        missingLists = empty.object,
        applicaties = empty.object,
        basesets = empty.object,
        bewerkingen = empty.object,
        entiteiten = empty.object,
        kerngegevens = empty.object,
        kernvelden = empty.object,
        applicatieMozKoppeling = empty.object,
        koppelingMozGegevens = empty.object,
        zibs = empty.object,
        itemIds = empty.array,
      },
      filter = empty.array,
      addFilter,
      removeFilter,
      removeAllFilters,
      ...rest
    }) => {
      const { selectionLists } = rest;
      const filterSets = groupFilters(filter, selectionLists);
      return {
        landscapeFilters: {
          ...landscapeFilters,
          filter,
          addFilter,
          removeFilter,
          removeAllFilters,
          selectedItemIds: flown(
            itemIds,
            map((itemId) => {
              const values = flown(
                [
                  filterValues("sel")(selections[itemId]),
                  filterValues("lst")(missingLists[itemId]),
                  filterValues("app", "itemId")(applicaties[itemId]),
                  filterValues("bas", "itemId")(basesets[itemId]),
                  filterValues("bew", "itemId")(bewerkingen[itemId]),
                  filterValues("ent", "itemId")(entiteiten[itemId]),
                  filterValues("ggv", "pageClusterId")(kerngegevens[itemId]),
                  filterValues("vld", "pageClusterId")(kernvelden[itemId]),
                  filterValues("zib", "itemId")(zibs[itemId]),
                  filterValues("apk", "itemId")(applicatieMozKoppeling[itemId]),
                  filterValues("kog", "itemId")(koppelingMozGegevens[itemId]),
                ],
                flatten,
                toSet
              );
              return {
                itemId,
                selected: filterSets.every((g) =>
                  g.some((value) => values.has(value))
                ),
              };
            }),
            keyBy("itemId"),
            mapValues(({ selected } = empty.object) => selected)
          ),
        },
        ...rest,
      };
    }
  )
);

export default enhanceLandscape;
