import empty from "empty";
import { hashHistory } from "react-router";
import {
  branch,
  compose,
  defaultProps,
  lifecycle,
  pure,
  setDisplayName,
  withHandlers,
  withProps,
  withState,
} from "recompose";

import { doUseFromBiebDialogAction } from "../actions/bieb";
import { leaveRouteAction } from "../actions/leave";
import {
  legoblokInitUseAction,
  legoblokMoveAction,
  legoblokReferencesAction,
} from "../actions/legoblok";
import { pageActions } from "../actions/page";
import {
  addUiClassAction,
  removeUiClassAction,
  showVersionsDrawerAction,
  updateSecondaryDrawerAction,
} from "../actions/ui";
import { toggleImageLightboxAction } from "../actions/utils";
import Page from "../components/page";
import { emptyPageForm } from "../reducers/page";
import {
  allLegoblokStructures,
  mapPageState,
  writeableLegoblokStructures,
} from "../selectors/page";
import connect from "./connect";

const withStateTab = compose(
  withState("selectedTab", "setTab", 0),
  withHandlers({
    tabChanged:
      ({ setTab }) =>
      (tab) =>
        setTab(tab),
  })
);

const withQueryTab = compose(
  withProps(
    ({ location: { query: { tab } = empty.object } = empty.object }) => ({
      selectedTab: parseInt(tab || 0, 10),
    })
  ),
  withHandlers({
    tabChanged:
      ({ location: { pathname } = empty.object }) =>
      (tab) =>
        hashHistory.replace(`${pathname}?tab=${tab}`),
  })
);

/**
 * Compose Higher-order component
 */
export default compose(
  setDisplayName("Page"),
  pure,
  // section indicates that this is just a section, not the main page
  defaultProps({ section: false }),
  connect((state, { params }) => mapPageState(state, params)),
  connect(
    ({
      stage: { stages = empty.array } = empty.object,
      data: {
        settings: {
          data: { enableCreateLegoblok = "false" } = empty.object,
        } = empty.object,
        addLegoblok,
      } = empty.object,
      form: {
        biebAddDialog: { busy: biebAddBusy = false } = empty.object,
        biebUseDialog: { busy: biebUseBusy = false } = empty.object,
        legoblokDialog: { busy: legoblokCreateBusy = false } = empty.object,
        legoblokUseDialog: { busy: legoblokUseBusy = false } = empty.object,
        applicationGegevenDialog: {
          busy: applicationGegevenBusy = false,
        } = empty.object,
        landscapeDirty: { dirty: landscapeDirty = false } = empty.object,
      },
      sagas: {
        orderRelations: {
          status: { loading: loadingOrderRelations },
        },
        orderRootItems: {
          status: { loading: loadingOrderRootItems },
        },
      },
    }) => ({
      stages,
      enableCreateLegoblok: enableCreateLegoblok === "true",
      landscapeDirty,
      legoblokBusy:
        biebAddBusy || biebUseBusy || legoblokCreateBusy || legoblokUseBusy,
      biebStructures: allLegoblokStructures(addLegoblok),
      legoblokStructures: writeableLegoblokStructures(addLegoblok),
      applicationGegevenBusy,
      orderTreeviewBusy: loadingOrderRelations || loadingOrderRootItems,
    }),
    (dispatch, { id, form: { page = emptyPageForm } }) => ({
      pageActions: pageActions(dispatch, id, page),
      uiActions: {
        addClass: (classname) => dispatch(addUiClassAction(classname)),
        removeClass: (classname) => dispatch(removeUiClassAction(classname)),
      },
      updateSecondaryDrawer: (open) =>
        dispatch(updateSecondaryDrawerAction(open)),
      confirmLeave: (nextPath) => dispatch(leaveRouteAction(nextPath)),
      toggleImageLightbox: (show, image) =>
        dispatch(toggleImageLightboxAction(show, image)),
      doUseLegoblokDialog: (open) => {
        dispatch(legoblokInitUseAction(open));
        dispatch(legoblokReferencesAction(id));
      },
      moveLegoblokTo: (itemId, newStructureId) =>
        dispatch(legoblokMoveAction(itemId, newStructureId)),
      doUseFromBiebDialog: (open) => dispatch(doUseFromBiebDialogAction(open)),
      showVersionsDrawer: (show) => dispatch(showVersionsDrawerAction(show)),
    })
  ),
  lifecycle({
    componentDidMount() {
      const { router, route, section } = this.props;
      if (section) {
        return;
      }

      const routeLeaveHook = ({ pathname }) => {
        const {
          stages,
          pageActions: {
            dirty = false,
            edit = false,
            unlock = empty.function,
          } = empty.object,
          landscapeDirty = false,
          confirmLeave,
        } = this.props;

        if ((dirty || landscapeDirty) && stages.length === 0) {
          confirmLeave(pathname);
          return false;
        }

        if (edit) {
          // vrijgeven pagina bij verandering van route
          unlock();
        }

        return true;
      };

      router.setRouteLeaveHook(route, routeLeaveHook);
    },

    componentDidUpdate() {
      const {
        stages,
        pageActions: { dirty = false, edit = false } = empty.object,
        landscapeDirty = false,
      } = this.props;
      if (((dirty || landscapeDirty) && stages.length === 0) || edit) {
        window.onbeforeunload = (event) => {
          event.preventDefault();

          // Tekst is alleen nog voor IE
          const message =
            "Let op: deze pagina blijft gemarkeerd als 'In bewerking' door u, als u het venster sluit.";
          event.returnValue = message;
          return message;
        };
      } else {
        window.onbeforeunload = null;
        window.onunload = null;
      }
    },
  }),
  branch(({ section }) => section, withStateTab, withQueryTab)
)(Page);
