import cytoscape from "cytoscape";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { gotoLocalAction } from "../../../actions/common";
import { snackbarShowAction } from "../../../actions/ui";
import {
  IndexViewVisibility,
  LandscapeView,
  SelectionListItem,
  SiteLink,
} from "../../../business/models";
import {
  landscapeItem as landscapeItemCell,
  landscapeViews,
} from "../../../sagas";
import Network from "./network";
import LandscapePopup from "./popup";

interface Rectangle {
  itmIdt: number;
  item: {
    slug: string;
    status?: SelectionListItem;
    validity?: SelectionListItem;
    type: string;
  };
  text: string;
  self: boolean;
  point: { x: number; y: number };
}

interface Line {
  from: number;
  to: number;
  links: Link[];
}

interface Link {
  siteLink?: SiteLink;
  lifecycle?: "nieuw" | "actief" | "oud";
  automationLevel?: string;
  direction: Direction;
}

interface Direction {
  from: number;
  to: number;
}

interface RectangleMap {
  [id: string]: Rectangle;
}
interface BewerkingMap {
  [id: string]: SiteLink[];
}
interface SelectedItemIds {
  [itmIdt: number]: boolean;
}

interface Props {
  itemId: number;
  landscape: {
    rectangles?: RectangleMap;
    bewerkingPerItem?: BewerkingMap;
    lines?: { [id: string]: Line };
  };
  filters: {
    selectedItemIds: SelectedItemIds;
  };
  landscapeItem?: SiteLink;
  uiActions: {
    addClass(className: string): void;
    removeClass(className: string): void;
  };
  handlePopupDialog(link?: { id: string; links: Link[] }): void;
  popup: { id: string; links: Link[] };
  pages: Record<number, { page: { pagetype: string } }>;
  mayEdit: boolean;
}

const emptyObject: {} = Object.freeze({});

const boxLabel = (s: string | null | undefined): string => {
  if (!s) return "";

  const maxLength = 40;
  if (s.length > maxLength) {
    s = s.slice(0, maxLength - 3) + "...";
  }

  while (/\S{18}/.test(s)) {
    s = s.replace(/(\S{17})(\S)/, "$1 $2");
  }

  return s;
};

const nothingSelected: { [itmIdt: number]: boolean } = {};

const checkItemIds = (
  ownPagetype: string,
  itemPagetype: string,
  itemIds: SelectedItemIds,
  selectedItemIds: SelectedItemIds,
  rectangles: RectangleMap
): boolean => {
  if (ownPagetype === "entiteit" && itemPagetype === "entiteit") {
    return Object.values(rectangles).every(({ itmIdt }) => itemIds[itmIdt]);
  }

  if (itemPagetype === "applicatie" || itemPagetype === "entiteit") {
    return (
      Object.keys(itemIds).length === Object.keys(selectedItemIds).length &&
      Object.keys(selectedItemIds).every(
        (key: string) => itemIds[Number(key)] === selectedItemIds[Number(key)]
      )
    );
  }

  return Object.keys(itemIds).length === 0;
};

const calcItemIds = (
  ownPagetype: string,
  itemPagetype: string,
  selectedItemIds: SelectedItemIds,
  rectangles: RectangleMap
): SelectedItemIds => {
  if (ownPagetype === "entiteit" && itemPagetype === "entiteit") {
    return Object.values(rectangles).reduce((acc, { itmIdt }) => {
      acc[itmIdt] = true;
      return acc;
    }, {} as { [itmIdt: number]: boolean });
  }

  if (itemPagetype === "applicatie" || itemPagetype === "entiteit") {
    return selectedItemIds;
  }

  return [];
};

const InnerLandscape = ({
  itemId,
  landscape: {
    rectangles = emptyObject,
    lines = emptyObject,
    bewerkingPerItem = emptyObject,
  } = emptyObject,
  landscapeItem,
  filters: { selectedItemIds = nothingSelected },
  popup,
  pages,
  uiActions: { addClass, removeClass },
  handlePopupDialog,
  mayEdit,
}: Props) => {
  const dispatch = useDispatch();
  const ownPagetype = pages[itemId]?.page?.pagetype ?? "applicatie";
  const itemPagetype = useMemo(() => {
    const first = Object.values(rectangles)[0];
    return first?.item.type ?? "applicatie";
  }, [rectangles]);
  const [itemIds, setItemIds] = useState(
    calcItemIds(ownPagetype, itemPagetype, selectedItemIds, rectangles)
  );
  const [dirty, setDirty] = useState(false);
  useEffect(() => {
    if (
      !checkItemIds(
        ownPagetype,
        itemPagetype,
        itemIds,
        selectedItemIds,
        rectangles
      )
    ) {
      setItemIds(
        calcItemIds(ownPagetype, itemPagetype, selectedItemIds, rectangles)
      );
    }
  }, [itemIds, ownPagetype, itemPagetype, rectangles, selectedItemIds]);

  const { value: allViews } = useSelector(landscapeViews.select);
  const views = useMemo(
    () => allViews?.filter((v: LandscapeView) => v.pagetype === itemPagetype),
    [allViews, itemPagetype]
  );
  const [view, setView] = useState<LandscapeView | undefined>(
    views?.find((v) => v.default)
  );

  const [, setFullscreen] = useState(false);
  const handleClose = useCallback(
    () => handlePopupDialog(),
    [handlePopupDialog]
  );
  const toggleFullscreen = useCallback(
    () =>
      setFullscreen((fullscreen) => {
        (fullscreen ? removeClass : addClass)("fullscreen");
        return !fullscreen;
      }),
    [addClass, removeClass]
  );
  const onNodeClick = useCallback(
    (id: string) => {
      // Do not go to group nodes
      if (!rectangles[id]) {
        return;
      }

      const { item } = rectangles[id];
      dispatch(gotoLocalAction(`page/${id}/${item.slug}`));
    },
    [dispatch, rectangles]
  );
  const onEdgeClick = useCallback(
    (sourceNodeId: string, targetNodeId: string) => {
      const from = Number(sourceNodeId);
      const to = Number(targetNodeId);
      const id = `${from}-${to}`;
      const links = Object.values(lines).flatMap((line) =>
        line.links.filter(
          (l) =>
            (l.direction.from === from && l.direction.to === to) ||
            (l.direction.from === to && l.direction.to === from)
        )
      );
      handlePopupDialog({ id, links });
    },
    [handlePopupDialog, lines]
  );

  const nodes = useMemo<cytoscape.NodeDefinition[]>(() => {
    const viewMap =
      view?.nodes.reduce((acc, n) => {
        acc[n.data.id!] = n;
        return acc;
      }, {} as { [id: string]: cytoscape.NodeDefinition }) ?? {};
    const ns = Object.entries(rectangles).map(
      ([id, r]): cytoscape.NodeDefinition => {
        const saved: cytoscape.NodeDefinition | undefined = viewMap[id];
        const visible =
          itemIds[r.itmIdt] ||
          Object.values(lines).some(
            ({ from, to, links }) =>
              (from === r.itmIdt || to === r.itmIdt) &&
              links.some(({ siteLink }) => itemIds[siteLink?.itemId!])
          );
        return {
          group: "nodes",
          data: {
            id,
            label: boxLabel(r.text),
            parent: saved?.data?.parent,
          },
          position: saved?.position ?? r.point,
          classes: [
            visible ? "visible" : "hidden",
            r.self ? "self" : r.item.status?.alias ?? "definitief",
            saved?.data?.parent ? "child" : "orphan",
          ],
        };
      }
    );
    const groups = view?.nodes.filter((n) => n.classes === "parent") ?? [];
    return ns.concat(groups);
  }, [rectangles, lines, itemIds, view]);

  const edges = useMemo<cytoscape.EdgeDefinition[]>(() => {
    const selfItmIdt = Object.values(rectangles).find((r) => r.self)?.itmIdt;
    return Object.entries(lines).map(([id, { links, to, from }]) => {
      const automationLevel =
        links.find((l) => l.automationLevel)?.automationLevel ?? "nil";
      const visibility =
        links.some((link) => itemIds[link.siteLink?.itemId!]) ||
        (itemIds[to] && itemIds[from])
          ? "visible"
          : "hidden";
      return {
        group: "edges",
        data: {
          id,
          source: from.toString(),
          target: to.toString(),
          self: links.some(({ siteLink }) =>
            bewerkingPerItem[siteLink?.itemId!]?.some(
              ({ itemId }) => itemId === selfItmIdt
            )
          ),
          label: links[0].siteLink?.label,
          linkCount: links.length,
          targetCount: links.filter((l) => l.direction.to === to).length,
          sourceCount: links.filter((l) => l.direction.from === to).length,
        },
        classes: [automationLevel, visibility],
      };
    });
  }, [rectangles, bewerkingPerItem, lines, itemIds]);

  const handleSave = useCallback(
    (
      name: string | true,
      nodes: cytoscape.NodeDefinition[],
      view?: LandscapeView
    ) => {
      if (view) {
        dispatch(
          landscapeViews.update(
            {
              ...view,
              default: view.default,
              name: name as string,
              visibility: IndexViewVisibility.public,
              nodes,
            },
            {
              onSuccess: () => {
                setDirty(false);
                return dispatch(
                  snackbarShowAction("Weergave opgeslagen", {
                    singleLine: true,
                  } as unknown as undefined)
                );
              },
              onFail: () =>
                dispatch(
                  snackbarShowAction("Fout bij opslaan", {
                    singleLine: true,
                  } as unknown as undefined)
                ),
            }
          )
        );
      } else {
        dispatch(
          landscapeViews.create(
            {
              name: name === true ? "Standaard" : name,
              default: name === true,
              pagetype: itemPagetype,
              visibility: IndexViewVisibility.public,
              nodes,
            },
            {
              onSuccess: () => {
                setDirty(false);
                dispatch(
                  snackbarShowAction("Weergave opgeslagen", {
                    singleLine: true,
                  } as unknown as undefined)
                );
                dispatch({ type: landscapeViews.events.invalidate });
                dispatch(
                  landscapeViews.require({
                    onSuccess: (result) => {
                      const body = result.body as unknown as LandscapeView[];
                      setView(body[body.length - 1]);
                      setDirty(false);
                    },
                  })
                );
              },
              onFail: () =>
                dispatch(
                  snackbarShowAction("Fout bij opslaan", {
                    singleLine: true,
                  } as unknown as undefined)
                ),
            }
          )
        );
      }
    },
    [dispatch, itemPagetype]
  );

  const handleDelete = useCallback(
    (view: LandscapeView) => {
      alert("Verwijderen " + view.name);
      dispatch(
        landscapeViews.delete(view, {
          onSuccess: () => {
            dispatch(
              snackbarShowAction("Weergave verwijderd", {
                singleLine: true,
              } as unknown as undefined)
            );
            setView(undefined);
            setDirty(false);
          },
          onFail: () =>
            dispatch(
              snackbarShowAction("Fout bij verwijderen", {
                singleLine: true,
              } as unknown as undefined)
            ),
        })
      );
    },
    [dispatch]
  );

  const landscapeItemValue = useSelector(landscapeItemCell.select);
  useEffect(() => {
    if (itemId && landscapeItemValue !== itemId) {
      dispatch({ type: landscapeViews.events.clear });
      dispatch(landscapeItemCell.set(itemId));
    }

    if (itemId) {
      dispatch(landscapeViews.require());
    }
  }, [dispatch, itemId, landscapeItemValue]);

  useEffect(() => {
    if (view && views?.find((v) => v.id === view.id)) {
      return;
    }

    const defaultView = views?.find((v) => v.default);

    if (view || defaultView) {
      setView(defaultView);
      setDirty(false);
    }
  }, [view, views]);

  useEffect(() => {
    dispatch({ type: "LANDSCAPEDIRTY_SET", payload: { dirty } });
  }, [dispatch, dirty]);

  const handleViewSelect = useCallback((view: LandscapeView | undefined) => {
    setView(view);
    setDirty(false);
  }, []);

  const handleChange = useCallback(() => {
    setDirty(true);
  }, []);

  const filters = useMemo(() => {
    return { selectedItemIds: itemIds };
  }, [itemIds]);

  return (
    <>
      <Network
        key={view?.id ?? 0}
        nodes={nodes}
        edges={edges}
        filters={filters}
        landscapeItem={landscapeItem}
        views={views}
        view={view}
        onViewSelect={handleViewSelect}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        onFullscreen={toggleFullscreen}
        onSave={handleSave}
        onChange={handleChange}
        maySaveDefault={mayEdit}
        onDelete={handleDelete}
      />
      <Dialog
        actions={[
          <FlatButton
            label="Sluiten"
            key="sluiten"
            primary
            onClick={handleClose}
          />,
        ]}
        open={Boolean(popup.id)}
        onRequestClose={handleClose}
        autoScrollBodyContent
      >
        <LandscapePopup popup={popup} rectangles={rectangles} pages={pages} />
      </Dialog>
    </>
  );
};

const Landscape = (props: Props) => (
  <InnerLandscape {...props} key={props.itemId} />
);

export default Landscape;
