/* eslint  react/prop-types: off */
import { loadingPresent } from "async-lifecycle";
import empty from "empty";
import { filter, uniqBy } from "lodash/fp";
import React from "react";
import { compose, mapProps, pure, setDisplayName } from "recompose";

import {
  Side,
  relationDisplayByRelationSide,
} from "../../../../business/relations";
import { flown } from "../../../../lodash";
import { RelationListWrapper, RelationsList } from "../../../relations";

const ModuleRelationsComponent = ({ itemId, relations, miniSaars, pagetype }) =>
  relations && relations.length > 0 ? (
    <RelationListWrapper sideBarList>
      <RelationsList
        itemId={itemId}
        type={`entiteit_${pagetype}`}
        side={1}
        label={`Komt voor in ${pagetype}`}
        list={relations}
        miniSaars={miniSaars}
        showHeader
      />
    </RelationListWrapper>
  ) : null;

const ModuleRelations = compose(
  setDisplayName("ModuleRelations"),
  pure,
  mapProps(({ chainRelations = empty.object, ...rest }) => {
    const relations = chainRelations
      ? relationDisplayByRelationSide(
          filter(
            ({ right: { type } = empty.object }) => type === rest.pagetype
          )(chainRelations.relations),
          false,
          loadingPresent(chainRelations.loading)
        )({ side: Side.left })
      : undefined;

    return {
      ...rest,
      relations:
        relations && relations.links
          ? flown(
              relations.links,
              uniqBy((l) => l.item?.itemId ?? l.id)
            )
          : undefined,
    };
  })
)(ModuleRelationsComponent);

const renderModuleRelations =
  (pagetype) =>
  ({ itemId, chainRelations, miniSaars }) =>
    (
      <ModuleRelations
        key={`module-rels-${pagetype}`}
        itemId={itemId}
        chainRelations={chainRelations}
        miniSaars={miniSaars}
        pagetype={pagetype}
      />
    );

export default renderModuleRelations;
