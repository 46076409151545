/* eslint  react/prop-types: off */
import empty from "empty";
import PropTypes from "prop-types";
import React from "react";
import { compose, defaultProps, setDisplayName, setPropTypes } from "recompose";

import Basecamp from "../../icons/basecamp";
import Highrise from "../../icons/highrise";
import { propTypes, withDefaultPageActions } from "./ggvtyp";
import PlainInput from "./plainInput";

const highriseFolders = Object.freeze({
  klant: "companies",
  leverancier: "companies",
  persoon: "people",
});

export const highriseFolder = ([pagetype]) =>
  highriseFolders[pagetype] || highriseFolders.klant;

/**
 * Gegevenstype 'Plat gegeven' (2)
 */
const PlatGegeven = ({
  value,
  setValue,
  field: {
    definition: {
      name,
      explanation,
      valuePlaceholder,
      maximumLength = 255,
      readOnly,
    } = empty.object,
  },
  className,
  pageActions: { edit },
  pagetypes = empty.array,
}) =>
  !readOnly && edit ? (
    <PlainInput
      value={value || ""}
      setValue={setValue}
      explanation={explanation}
      maxLength={maximumLength}
      placeholder={valuePlaceholder}
    />
  ) : /^ID4?$/.test(name) ? (
    <Basecamp id={value} name={name} />
  ) : name === "Highrise-ID" ? (
    <Highrise folder={highriseFolder(pagetypes)} id={value} />
  ) : (
    <span className={className}>{value}</span>
  );

const enhance = compose(
  setDisplayName("PlatGegeven"),
  setPropTypes({
    ...propTypes,
    field: PropTypes.shape({
      value: PropTypes.string,
    }).isRequired,
  }),
  defaultProps({
    className: "platGegeven",
  }),
  withDefaultPageActions()
);

export default enhance(PlatGegeven);
