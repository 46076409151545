import {
  IconButton,
  IconMenu,
  RadioButton,
  RadioButtonGroup,
  RaisedButton,
  Subheader,
} from "material-ui";
import { useCallback, useState } from "react";

import DownloadIcon from "../../../svg/Download";

export interface DownloadMenuProps {
  download(viewport?: boolean, transparent?: boolean): void;
}

const DownloadMenu = ({ download }: DownloadMenuProps) => {
  const [viewport, setViewport] = useState(false);
  const [transparent, setTransparent] = useState(false);
  const onDownload = useCallback(
    () => download(viewport, transparent),
    [download, viewport, transparent]
  );
  return (
    <IconMenu
      iconButtonElement={
        <IconButton>
          <DownloadIcon />
        </IconButton>
      }
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      targetOrigin={{ horizontal: "left", vertical: "top" }}
    >
      <Subheader style={{ color: "#111", fontSize: "110%" }}>
        Downloaden als PNG
      </Subheader>
      <Subheader>Selectie</Subheader>
      <RadioButtonGroup
        name="viewport"
        valueSelected={JSON.stringify(viewport)}
        onChange={(_, value) => setViewport(value === "true")}
        labelPosition="right"
        style={{ marginLeft: 16 }}
      >
        <RadioButton value="false" label="Volledig landschap" />
        <RadioButton
          value="true"
          label="Weergegeven gedeelte"
          style={{ marginTop: 8 }}
        />
      </RadioButtonGroup>
      <Subheader>Achtergrond</Subheader>
      <RadioButtonGroup
        name="viewport"
        valueSelected={JSON.stringify(transparent)}
        onChange={(_, value) => setTransparent(value === "true")}
        labelPosition="right"
        style={{ marginLeft: 16 }}
      >
        <RadioButton value="false" label="Wit" />
        <RadioButton
          value="true"
          label="Transparant"
          style={{ marginTop: 8 }}
        />
      </RadioButtonGroup>
      <RaisedButton
        primary
        label="Downloaden"
        onClick={onDownload}
        style={{ marginLeft: 16, marginTop: 16 }}
      />
    </IconMenu>
  );
};

export default DownloadMenu;
