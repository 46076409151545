import { addStage, redirectAction } from "./common";

export const leaveAction = (action) =>
  addStage({
    message: "Als je de pagina verlaat, worden de wijzigingen niet opgeslagen.",
    action: (dispatch) => {
      dispatch({ type: "LANDSCAPEDIRTY_SET", payload: { dirty: false } });
      dispatch(action);
    },
  });

export const leaveRouteAction = (nextPath) =>
  leaveAction(redirectAction(nextPath));
